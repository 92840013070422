<script setup lang="ts">
import { Badge, Space, type TablePaginationConfig } from 'ant-design-vue'
import { queryLinkageRecordsApi } from '@/api/linkage'

const params = ref<any>({ current: 1, size: 20 } as any)
const search = ref<TableSearchType[]>([
  { label: '规则名称', name: 'linkageName', placeholder: '请输入规则名称', type: 'input', required: false },
  { label: '最早执行时间', name: 'dateFrom', placeholder: '请选择', type: 'date', required: false },
  { label: '最晚执行时间', name: 'dateTo', placeholder: '请选择', type: 'date', required: false },
])
const state = reactive<TableState>({
  data: [],
  columns: [
    { title: '联动规则', dataIndex: 'linkageName' },
    { title: '联动执行时间', dataIndex: 'createdAt', customRender: ({ record }) => useDateFormat(record.createdAt, 'YYYY年MM月DD日 HH:mm:ss').value },
    { title: '触发器', dataIndex: 'triggerDesc' },
    { title: '执行条件', dataIndex: 'conditions' },
    { title: '执行动作', dataIndex: 'actions', customRender: ({ record }) => h(Space, { direction: 'vertical' }, () => getActions(record).map(action => h(Badge, { color: 'blue', status: 'default', text: action }))) },
  ],
  config: {
    total: 0,
    loading: false,
    isBorder: false,
    isSerialNo: true,
    isSelection: true,
    isOperate: true,
  },
  pagination: {
    position: ['bottomLeft'],
    pageSize: 20,
    pageSizeOptions: ['20', '30', '40', '50'],
    total: 0,
    current: 1,
    showTotal: total => `共${total}条`,
  },
})

onMounted(refresh)
async function refresh() {
  state.config.loading = true
  const { records, current, size, total } = await queryLinkageRecordsApi(params.value)
  state.data = records
  const pagination = state.pagination as TablePaginationConfig
  pagination.current = current
  pagination.pageSize = size
  pagination.total = total
  state.config.loading = false
  setTimeout(() => {
    state.config.loading = false
  }, 500)
}

async function onSearch(form: any) {
  params.value = { ...form, current: 1, size: (state.pagination as TablePaginationConfig).pageSize ?? 20 }
  await refresh()
}

async function onTablePageChange(current: number, pageSize: number) {
  (state.pagination as TablePaginationConfig).current = current;
  (state.pagination as TablePaginationConfig).pageSize = pageSize
  params.value.current = current
  params.value.size = pageSize

  await refresh()
}

function getActions(record: any) {
  const actions: string[] = []

  record.actions?.forEach((item: any) => {
    const successOrError = item.success ? '执行成功' : `执行失败：${item.error}`
    let str: any
    if (item.operation === 'CALL_SERVICE') {
      str = `设备（编号：${item.deviceCode}，名称：${item.deviceName}）执行高级功能：${item.abilityName}，${successOrError}`
    }

    if (item.operation === 'WRITE_PROPERTY') {
      str = `设备（编号：${item.deviceCode}，名称：${item.deviceName}）修改属性：${item.abilityName}，数值：${item.data}，${successOrError}`
    }

    if (item.operation === 'VIDEO') {
      str = `联动视频设备：${item.deviceName}，${successOrError}`
    }

    if (str) {
      actions.push(str)
    }
    else {
      actions.push('未知的联动动作')
    }
  })

  return actions
}
</script>

<template>
  <page-container>
    <content-header title="联动记录" />
    <flex-content>
      <div h-full overflow-hidden flex flex-col>
        <div rounded p-12px mb-8px style="background: var(--bg-color)">
          <search-form :search="search" :wrapper-col="{ span: 17 }" :label-col="{ span: 7 }" @search="onSearch" />
        </div>
        <table-layout :need-header="false">
          <template #content>
            <base-table v-bind="state" @page-change="onTablePageChange" />
          </template>
        </table-layout>
      </div>
    </flex-content>
  </page-container>
</template>
